import React from "react";
import { Switch, Route } from 'react-router-dom';
import ConsultaEstacionamento from "../pages/main/ConsultaEstacionamento";

export default function Routes() {  

    const OpenRoute = ({component: Component,...rest}) => (
        <Route {...rest} render={props =>
            <Component {...props}/>
       
        
        }/>
        );

    return (
        <Switch>
            <OpenRoute path="/"  component={ConsultaEstacionamento} />
       </Switch>
    );
}
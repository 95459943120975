import React,{useEffect, useState} from "react";
import { get } from "../../store/config/register";
import './ConsultaEstacionamento.css'

export default function ConsultaEstacionamento(){
const [estacionamento,setEstacionamentos] = useState([])

    useEffect(()=>{
        buscaEstacionamento();
    },[])

async function buscaEstacionamento(){
   let estacs = await get('/Local/v1/Disponivel')
   setEstacionamentos(estacs)
   console.log(estacionamento);
}
    
    return (
        <div className="tabela">
             <table>
                <thead>
                    <tr>
                    <th scope="col" className="Estacionamento">Estacionamento</th>
                    <th scope="col" className="Vagas">Vagas</th>
                    </tr>
                </thead>
                <tbody>
                {estacionamento.map((estacionamento,index) =>{
                return (
                    <tr key={index}>
                        <td data-label="Estacionamento">{estacionamento.nome}</td>
                        <td data-label="Vagas">{estacionamento.vagas}</td>                 
                   </tr>
                )
            })}
                </tbody>
             </table>
            
        </div>
    );
}
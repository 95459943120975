import {getLocalStorage} from './storage';
import Api from './Api.js'; 

let token= {token: getLocalStorage("acessToken"),expiration: getLocalStorage("expiration")};

export const post=async(url,data)=>{
    const result=   await Api.request('post',url,data);
 
    return result;
 
 }
 
 export const put=async (url,data)=>{
     const result=   await Api.request('put',url,data);
 
    return result;
 }
 
 export const get=async (url)=>{
 
     const result=   await Api.request('get',url);
 
     return result;
 }
 
 export const del=async (url)=>{
 
     const result=   await Api.request('delete',url);
 
     return result;
  }